// import Testimonial from './Testimonial'; // Import the component
import React from 'react';
const Testimonial = ({ testimonial }) => {
    return (
        <div className="testimonial-container">
            <div className="testimonial-content">
                <p>{testimonial.quote}</p>
            </div>
            <div className="testimonial-author">
                <p>{testimonial.name}</p>
                <div className="star-rating">
                    {[...Array(testimonial.rating)].map((_, i) => (
                        <span key={i} className="star">★</span>
                    ))}
                </div>
            </div>
        </div>
    );
};

const testimonials = [
    {
        id: 1,
        name: <i>Brendan Abrassart-White Associate Creative Director</i>,
        quote: <span>&quot;Samant is a wonderful programmer who understands his craft intimately. I've found this is a result of always being open and willing to learn. He is easy to work with and a joy to be around.&quot;</span>,
        rating: 5,
    },
    {
        id: 1,
        name: <i>Fred Gerantabee - Global VP, Digital Innovation</i>,
        quote: <span>&quot;Samant was one of my favorite people to work with - he's kind, patient and has a very even keeled temperament which is key for being part of a successful software development team. He understood how to solve a problem, and knew how to patiently work his way through it to get it just right. His FE dev skills are solid, and that was back when - i'm sure he's gotten *10 since then.&quot;</span>,
        rating: 5,
    },
    {
        id: 1,
        name: <i>Romi Bhattacharjee - HR Manager</i>,
        quote: <span>&quot;Sam is detailed oriented manager who watches the balance sheet like hawk without losing sight of strategic objective. He is very friendly natured and can manage so many peoples at the same time. I wish all the very best to him for his all future endivour.&quot;</span>,
        rating: 5,
    },
    {
        id: 1,
        name: <i>Laura Dalton - Product Owner</i>,
        quote: <span>&quot;I had the pleasure of working with Samant during my time at Gartner, where I was a product manager and Samant was the lead engineer on the team. Samant, in addition to being a highly-skilled developer who produced high quality work in a short period of time, has a unique talent of identifying innovative solutions, while balancing technical trade-offs. He always had a thorough understanding of business requirements and was able to easily communicate to technical and non-technical audiences alike. I hope that I get to work with Samant again in the near future&quot;!!</span>,
        rating: 5,
    },
    // ... more testimonials
];

const TestimonialSlider = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="title-box text-center">
                    <h3 className="title-a">Testimonials</h3>
                    <div className="line-mf"></div>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="box-shadow-full">
                    {testimonials.map((testimonial) => (
                        <Testimonial key={testimonial.id} testimonial={testimonial} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;